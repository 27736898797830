<template>
  <div class="flex-container non-scrollable h-full">
    <div class="scrollable">
      <div class="flex flex-column">
        <page-title value="Saisie dépense" class="mb-4"/>
        <div class="flex flex-row align-items-center justify-content-between mb-2">
          <Dropdown id="nature" v-model="nature" :options="ndfNatures" placeholder="Nature"
                    option-label="label" option-group-label="label" option-group-children="items"
                    class="w-full" input-class="w-full"/>
        </div>
        <div class="flex flex-row align-items-center justify-content-between mb-2 p-float-label mt-3">
          <InputNumber id="htPrice" v-model="htPrice" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus
                       class="w-full" input-class="w-full" @input="updateTTC('htPrice', $event)"/>
          <label for="htPrice">Prix HT</label>
        </div>
        <div class="flex flex-row align-items-center justify-content-between mb-2 p-float-label mt-3">
          <InputNumber id="tvaPrice" v-model="tvaPrice" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus
              class="w-full" input-class="w-full" @input="updateTTC('tvaPrice', $event)"/>
          <label for="tvaPrice">Prix TVA</label>
        </div>
        <div class="flex flex-row align-items-center justify-content-between mb-2 p-float-label mt-3">
          <InputNumber id="ttcPrice" :model-value="ttcPrice" locale="fr-FR" mode="currency" currency="EUR"
              class="w-full" input-class="w-full" :disabled="true" highlightOnFocus/>
          <label for="ttcPrice">Prix TTC</label>
        </div>
        <div class="flex flex-row align-items-center justify-content-between mb-2 mt-3">
          <Calendar id="date" v-model="date" :showIcon="true" date-format="dd/mm/yy" placeholder="Date"
                    required="true" touch-u-i class="w-full" input-class="w-full"/>
        </div>
        <div class="flex flex-row align-items-center mt-4 mb-2">
          <label for="refacturable" class="mr-5">Refacturable client</label>
          <div>
            <Checkbox id="refacturable" v-model="refacturable" :binary="true"/>
          </div>
        </div>
        <div v-if="refacturable" class="flex flex-row align-items-center justify-content-between mb-2">
          <label for="mission">Mission</label>
          <Dropdown v-if="missions" id="mission" v-model="mission" :options="missions" :option-label="(val) => val.clientContract.name">
          </Dropdown>
        </div>
      </div>
      <img v-if='imagePath !== ""' :src="imagePath" class="max-w-10rem" alt="photo telephone">
    </div>
  </div>
  <div class="absolute bottom-0 left-0 w-full z-2">
    <div>
      <Button
          class="p-button-sm w-6 justify-content-center"
          style="border-radius: 0 0 0 10px; box-shadow: 0 0 2px rgb(0 0 0 / 25%)"
          @click="takePicture(true)"
      >
        Choisir une image
      </Button>
      <Button
          class="p-button-sm w-6 justify-content-center"
          style="border-radius: 0 0 10px 0; box-shadow: 0 0 2px rgb(0 0 0 / 25%)"
          :disabled="!isSavable"
          @click="saveExpense"
      >
        Affecter la dépense
      </Button>
    </div>
  </div>
</template>

<script>
import Alert from "@/utils/Alert";
import {mapState} from "vuex";
import {store} from "@/store";
import PageTitle from "@/components/PageTitle.vue";
// todo: always purpose to take a photo or purpose by default and on leave, the user can choose between the two options take a picture or take an existing one ?
export default {
  name: "ExpenseAccountMobile",
  components: {PageTitle},
  data() {
    return {
      imagePath: "",

      expenseProofOcrResult: [],
      expenseProofFormData: null,

      missions: [],
      refacturable: false,
      mission: null,
      nature: null,
      htPrice: 0,
      tvaPrice: 0,
      ttcPrice: 0,
      date: null,
      ndfNatures: [],

      cameraConfig: {}
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      settings: state => state.misc.settings,
      independent: state => state.independent.independent,
      SAME_ROUTE_CLICKED_FLAG: state => state.misc.SAME_ROUTE_CLICKED_FLAG
    }),
    isSavable () {
      return !(this.refacturable && !this.mission) && this.nature && this.date;
    }
  },
  watch: {
    SAME_ROUTE_CLICKED_FLAG (value) {
      if (value) {
        // recall the take picture function -- might need to reset
        this.takePicture()
      }
    },
  },
  async created () {
    this.$store.state.misc.loading = true
    await store.dispatch('sidebar/changeMenu', 'Note de frais')
    await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
    this.missions = await this.$store.dispatch('mission/getMissionsByIndependent', this.independent)
    this.ndfNatures = [
      {
        label: 'Notes de frais',
        items: this.settings.NDF.categoriesNdf.filter(cndf => cndf.enabled)
      },
      {
        label: 'Dispositifs sociaux',
        items: this.settings.NDF.categoriesDs.filter(cds => cds.enabled)
      }
    ]
    this.$store.state.misc.loading = false
    this.initCameraConfig()
    this.takePicture()
  },
  methods: {
    updateTTC (attribute, event) {
      this[attribute] = event.value
      this.ttcPrice = this.htPrice + this.tvaPrice
    },
    initCameraConfig () {
      if (navigator.camera) {
        this.cameraConfig = {
          quality: 50,
          sourceType: navigator.camera.PictureSourceType.CAMERA,
          destinationType: navigator.camera.DestinationType.FILE_URI,
          encodingType: navigator.camera.EncodingType.JPEG,
          mediaType: navigator.camera.MediaType.PICTURE,
          cameraDirection: navigator.camera.Direction.BACK
        }
      }
    },
    async saveExpense () {
      if (this.refacturable && !this.mission) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez renseigner une mission', life: 3000})
        return
      }

      if (!this.nature) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez renseigner une nature', life: 3000})
        return
      }

      this.$store.state.misc.loading = true

      const expense = {
        refacturable: this.refacturable,
        mission: this.mission?.id ?? null,
        ht: this.htPrice * 100,
        tva: this.tvaPrice * 100,
        ttc: this.ttcPrice * 100,
        nature: this.nature,
        date: this.date.toISOString(),
        independent: this.independent?.id,
        item: []
      }

      if (this.expenseProofFormData) {
        const uploadedFile = await this.$store.dispatch('file/uploadFile', this.expenseProofFormData)
        expense['justificatif'] = uploadedFile.id
      }

      if (this.expenseProofOcrResult && this.expenseProofOcrResult[0] && this.expenseProofOcrResult[0].children) {
        this.expenseProofOcrResult[0].children.forEach((child) => {
          expense.item.push({
            nom: child.data.nature,
            ht: child.data.amountexvat * 100,
            tva: child.data.vatamount * 100,
            ttc: child.data.amount * 100
          })
        })
      }

      const result = await this.$store.dispatch('depense/createDepense', expense)
      await this.$store.dispatch('depense/assignDepense', result)

      this.$store.state.misc.loading = false
      this.$router.push({ name: 'NdfMobile' })
    },
    takePicture(isImport = false) {
      // todo: should force the reset even in import mode ?
      if (navigator.camera) {
        console.log('navigator.camera', navigator.camera)
        this.cameraConfig.sourceType = (isImport) ? navigator.camera.PictureSourceType.PHOTOLIBRARY : navigator.camera.PictureSourceType.CAMERA
        navigator.camera.getPicture((data) => {
          // todo: verify if working with real devices
          this.takePictureReset()
          this.setPicture((isImport ? 'file://' : '') + data)
        }, (e) => {
          console.log(e)
          if (e !== 'No Image Selected') return this.error()
        }, this.cameraConfig)
      } else {
        this.error();
      }
    },
    takePictureReset () {
      this.expenseProofOcrResult = null
      this.expenseProofFormData = null
      this.imagePath = ""

      this.mission = null
      this.refacturable = false
      this.nature = null
      this.htPrice = 0
      this.tvaPrice = 0
      this.ttcPrice = 0
      this.date = null
    },
    setPicture(imagePath){
      // this.cameraConfig.sourceType = (isImport) ? navigator.camera.PictureSourceType.PHOTOLIBRARY : navigator.camera.PictureSourceType.CAMERA
      this.getFileEntry(imagePath)
          .then((res) => {
            this.imagePath = res.url
          })
          .catch((e) => {
            console.log(e)
          })
    },
    async getFileEntry (fileURL) {
      let context = this
      return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(fileURL, (fileEntry) => {
          fileEntry.file(file => {
            const reader = new FileReader()
            reader.readAsArrayBuffer(file)
            reader.onloadend = () => {
              const imgBlob = new Blob([reader.result], {
                type: file.type
              })

              const resData = {
                name: fileEntry.name,
                url: window.URL.createObjectURL(imgBlob),
                blob: imgBlob,
                fileEntry: fileEntry
              }

              context.expenseProofFormData = new FormData()
              context.expenseProofFormData.append('filename', resData.name)
              context.expenseProofFormData.append('file', imgBlob)
              this.$store.dispatch('klippa/getOcr', context.expenseProofFormData)
                .then((res) => {
                  context.expenseProofOcrResult = res
                  // console.log('context.expenseProofOcrResult', context.expenseProofOcrResult)
                  this.ht = (this.expenseProofOcrResult[0]?.data?.amountexvat) ?? 0
                  this.tva = (this.expenseProofOcrResult[0]?.data?.vatamount) ?? 0
                  this.ttc = (this.expenseProofOcrResult[0]?.data?.amount) ?? 0
                  this.nature = this.expenseProofOcrResult[0]?.nature ?? null
                  this.date = (this.expenseProofOcrResult[0]?.data?.date) ?? new Date()
                })
                .catch((e) => {
                  console.log(e)
                })

              window.URL.revokeObjectURL(imgBlob)
              resolve(resData)
            }
          }, (error) => {
            console.log(error)
            reject(error)
          })
        })
      })
    },
    error(){
      Alert.errorMessage(this, 'cameraPhotoError')
    },
  }
}
</script>

<style scoped>

</style>
